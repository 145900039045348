const menu = document.querySelector('.burger-menu');

if (menu) {
    document.addEventListener('DOMContentLoaded', () => {
        document.querySelector('.burger-icon').addEventListener('click', () => {
            if (!menu.classList.contains('collapsed')) {
                menu.classList.add('collapsed');
                menu.classList.remove('closed');
            }
        });

        menu.querySelector('.close-menu').addEventListener('click', () => {
            closeMenu();
        });

        window.addEventListener('keyup', e => {
            if (e.key === 'Escape') {
                closeMenu();
            }
        });
    });

    const closeMenu = () => {
        menu.classList.remove('collapsed');
        menu.classList.add('closed');
    };
}

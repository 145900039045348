const {Router} = require('../../../js/common/router');
const spinner = document.createElement('span');
const menu = document.querySelector('#right-header');
const lang = document.documentElement.lang;
spinner.classList.add('spinner-border', 'spinner-border-sm', 'mx-3');

/**
 * Check user login status and
 * get flash messages from controller and display them in alerts
 *
 * @access public
 */
export function checkLogin() {
    menu.append(spinner);
    $.ajax({
        url: Router.generate(
            'login_status.' + lang,
            {'showroom': document.documentElement.getAttribute('data-showroom')}
        ),
        method: 'GET',
        global: false,
        success: function (data) {
            if (data['logged']) {
                enableUser();
            } else {
                disableUser();
            }
            if (data['flash']) {
                const flashMessages = Object.entries(data['flash']);
                for (const [type, message] of flashMessages) {
                    addFlash(type, message);
                }
            }
            if (data['expiration'] && data['expiration'] !== null) {
                import('../../common/js/_expiration_countdown').then(({start}) => {
                    start(data['expiration'])
                })
            }

            const event = new CustomEvent('login', {
                detail: {
                    logged: data.logged,
                    newsletter: data.newsletter,
                    optin: data.optin
                }
            });

            document.dispatchEvent(event);
        },
        error: function () {
            console.error('undefined login status');
            disableUser();
        }
    });
}

const enableUser = () => {
    $('.js-login-menu').hide();
    $('.js-signup-menu').hide();
    $('.js-account-menu').show();
    menu.removeChild(spinner);
};

const disableUser = () => {
    $('.js-account-menu').hide();
    $('.js-signup-menu').show();
    $('.js-login-menu').show();
    menu.removeChild(spinner);
};

const addFlash = (type, message) => {
    const flash = document.createElement('div');
    flash.className = `alert fade show mb-3 alert-${type}`;
    flash.textContent = message;
    document.querySelector('.flash').append(flash);
};

// any CSS you require will output into a single css file (app.css in this case)
require('../scss/global.scss');
const $ = require('jquery');
require('select2/dist/js/select2');
$.fn.select2.defaults.set("theme", "bootstrap");
require('bootstrap');
require('./_scroll-top');
require('./_burger-menu');
require('navigator.sendbeacon');
require('./_polyfills');
import * as tts from './_tts';
import * as user from '../../account/js/_user';
import * as Sentry from '@sentry/browser';
import imgLazyload from './_img-lazyload';

const clearIcon = $('.filters-block').attr('data-clear-icon');

document.addEventListener('DOMContentLoaded', () =>  {
    user.checkLogin();
    render();
    imgLazyload();
    console.log('Application ready');
    tts.page();
    const env = document.documentElement.getAttribute('data-env');
    if (env !== "dev") {
        // Browser monitoring
        Sentry.init({
            dsn: 'https://9805da295ee54dee93d17bb2184e28a2@o429803.ingest.sentry.io/5377095',
            environment: env,
        });
    }
    document.querySelector('body').addEventListener('click', initOutboundLink);
});

const render = () => {
    $('.select2-selection__clear, .select2-selection__choice__remove').empty().append(clearIcon).show();
}

const initOutboundLink = (e) => {
    let selector;
    if (e.target.tagName === 'A') {
        selector = e.target;
    } else if (e.target.parentElement.tagName === 'A') {
        selector = e.target.parentElement;
    }
    if (
        selector &&
        selector.hostname !== window.location.hostname &&
        !selector.href.includes('mailto')
    ) {
        tts.outboundLink(selector.hostname, selector.pathname);
    }
}

const $ = require('jquery');
const container = $('#content');
const scrollTop = $('#scrollTop');
$(document).ready(function () {
    $(window).on('scroll', function () {
        if ($(this).scrollTop() > $(container).offset().top) {
            scrollTop.fadeIn();
        } else {
            scrollTop.fadeOut();
        }
    });
    $(scrollTop).on('click', function (e) {
        e.preventDefault();
        $('html').animate({scrollTop: 0}, 700);
    });
});

const {Router} = require('../../../js/common/router');

// the element to look for get page data-attributes
const selector = $('main');

const showroom = document.documentElement.dataset.showroom;
const fingerprintKey = `${showroom}_fingerprint`;

/**
 * Send photo view action tts event
 *
 * @param {string[]} [photos]
 * @param {string} [route]
 * @access public
 */
export function page(photos = undefined, route = undefined) {
    const query = Object.assign(
        {'uri': window.location.href},
        getCurrentPage(),
        getRouteParams(),
        getQuery(),
        photos !== undefined ? {'photos': photos} : getPhotos(),
        getTotalCount(),
        getFiltersCurrentState(),
        getSessid(),
    );
    navigator.sendBeacon(Router.generate(
        'tts_page.en',
        {
            'route': route === undefined ? selector.attr('data-current-route') : route,
            'showroom': document.documentElement.getAttribute('data-showroom')
        }
    ), objectToFormData(query));
}

/**
 * Get user sessid & fingerprint
 *
 * @access private
 */
function getSessid() {
    return {
        'sessid': selector.attr('data-sessid'),
        'fingerprint': localStorage.getItem(fingerprintKey) ?? generateFingerprint()
    };
}

function generateFingerprint() {
    const random = Math.random().toString(36).substr(2);
    const fingerprint = [Date.now(), random].join('-');

    localStorage.setItem(fingerprintKey, fingerprint);

    return fingerprint;
}

function objectToFormData(object) {
    let formData = new FormData();
    const properties = Object.keys(object);
    for (const property of properties) {
        formData.append(property, object[property]);
    }

    return formData;
}

/**
 * Get current page query
 *
 * @access private
 */
function getQuery() {
    let dataQuery = {};
    if (selector.attr('data-query')) {
        try {
            dataQuery = selector.attr('data-query') ? JSON.parse(selector.attr('data-query')) : {};
        } catch (e) {
            console.error('parsing error: ', e);
        }
    }
    return dataQuery;
}

/**
 * Get current page photos
 *
 * @access private
 */
function getPhotos() {
    return selector.attr('data-photos') ? {'photos': selector.attr('data-photos')} : {};
}

/**
 * Get query total count
 *
 * @access private
 */
function getTotalCount() {
    const count = $('#count-results').attr('data-total-count');

    return count ? {'count': parseInt(count)} : {};
}

/**
 * Get query total count
 *
 * @access private
 */
function getCurrentPage() {
    return {'page': selector.attr('data-current-page') ? parseInt(selector.attr('data-current-page')) : null};
}


/**
 * Get query total count
 *
 * @access private
 */
function getRouteParams() {
    return JSON.parse(selector.attr('data-route-params'));
}

/**
 * Send media photo view action tts event
 * @access public
 * @param {string} slug
 * @param {('request_add'|'photo_zoom'|'photo_view'|'photo_download')} type
 */
export function photo(slug, type) {
    const data = Object.assign(
        {
            'uri': window.location.href,
            'event': type,
        },
        getCurrentPage(),
        getRouteParams(),
        getQuery(),
        getTotalCount(),
        getFiltersCurrentState(),
        getSessid(),
    );
    navigator.sendBeacon(Router.generate(
        'tts_media.en',
        {
            'slug': slug,
            'showroom': document.documentElement.getAttribute('data-showroom')
        }
    ), objectToFormData(data));
}

export const outboundLink = (hostname, pathname) => {
    const data = Object.assign(
        {
            'uri': window.location.href,
            'event': 'outbound_link',
        },
        getCurrentPage(),
        getRouteParams(),
        getQuery(),
        getFiltersCurrentState(),
        getSessid(),
        {
            hostname,
            pathname,
        }
    );
    navigator.sendBeacon(Router.generate(
        'tts_outbound.en',
        {
            'showroom': document.documentElement.getAttribute('data-showroom')
        }
    ), objectToFormData(data));
}

/**
 * Get filters current state
 */
function getFiltersCurrentState() {
    let state = {};
    const filters = $('form.filters > .filter');
    filters.each(function () {
        let field = $(this).find('input,select').first();
        let value = field.val();
        if ($.isArray(value)) {
            value = value.join(',');
        }
        if (value) {
            state[field.attr('id')] = value;
        }
    });

    return state;
}

/**
 * Send photo records action tts event
 *
 * @param {string} event
 * @param {string[]} [photos]
 * @access public
 */
export function photos(event, photos = undefined) {
    const query = Object.assign(
        {'uri': window.location.href},
        getCurrentPage(),
        getRouteParams(),
        getQuery(),
        photos !== undefined ? {'photos': photos} : getPhotos(),
        getTotalCount(),
        getFiltersCurrentState(),
        getSessid(),
    );
    navigator.sendBeacon(Router.generate(
        'tts_photos.en',
        {
            'route': selector.attr('data-current-route'),
            'event': event,
            'showroom': document.documentElement.getAttribute('data-showroom')
        }
    ), objectToFormData(query));
}
